import React from 'react';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForever from '@material-ui/icons/DeleteForever';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import EditIcon from '@material-ui/icons/Edit';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import AppBar from '@material-ui/core/AppBar';
import TabList from '@material-ui/lab/TabList';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import PublishIcon from '@material-ui/icons/Publish';

import {
  Button,
  Input,
  Grid,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import bg from 'assets/img/Splash_2_std.jpg';

import Loader from 'react-loader-spinner';

import { Link, useHistory } from 'react-router-dom';

// core components
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js';
import FooterBlackSocial from 'components/Footers/FooterBlackSocial';

// Components
import { StatsGraph } from 'components/StatsGraph';

import { fireStore } from 'config/fire';

import AuthContext from 'Context/AuthContext';
import { Databehandler } from './Databehandler';
import axios from 'axios';

import warning from 'assets/img/warning.png';

export const MinsidePage = () => {
  // USE EFFECT CLOSE NAVBAR
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  // USECONTEXT STATE
  const context = React.useContext(AuthContext);
  const history = useHistory();

  // LOCAL STATE
  const [value, setValue] = React.useState('2');
  const [layout, setLayout] = React.useState('');
  const [FileSizeToBig, setFileSizeToBig] = React.useState(false);
  const [addingSubs, setaddingSubs] = React.useState(false);
  const [dialogBox, setdialogBox] = React.useState({ status: false, id: '' });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // TESTING
  var testing = 0;

  // GET DATE
  var d = new Date();
  var getDate =
    String(d.getDate()) +
    '.' +
    String(d.getMonth() + 1) +
    '.' +
    String(d.getFullYear());

  // Local STATE

  const [loading, setloading] = React.useState(false);

  // Sleep function
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  // STYLES

  const matches = useMediaQuery('(min-width:700px)');

  const DashCardQuery = () => {
    if (matches) {
      console.log(matches);
      return { padding: 0 };
    } else {
      return null;
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
      padding: 30,
    },
    container: {
      minWidth: '100%',
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      maxWidth: '20vw',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    logo: {
      maxWidth: '20vw',
    },
    DashButtons: {
      minWidth: '100%',
      margin: '10px',
    },
  }));
  const classes = useStyles();

  const LoadingSpinner = () => {
    return (
      <div className="overlay">
        <Loader
          className="loader"
          type="ThreeDots"
          color="rgba(0,0,240,.6)"
          height="100"
          width="100"
        />
      </div>
    );
  };

  const DownloadPoster = async (data) => {
    setloading(true);
    var POSTER_URL = testing
      ? `http://localhost:5000/c/poster/${data.id}`
      : `/c/poster/${data.id}`;
    await axios
      .get(POSTER_URL, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
        setloading(false);
      });
  };

  const DeleteDialogBox = (id) => {
    return (
      <Dialog
        open={dialogBox.status}
        onClose={dialogBox.status}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { minWidth: '30vw' },
        }}
      >
        <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
          <span style={{ color: 'black' }}>{'Advarsel'}</span>
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <img
            style={{
              height: '15vh',
              marginBottom: '10px',
            }}
            src={warning}
          />

          <DialogContentText id="alert-dialog-description">
            Er du sikker på du vil slette denne lokasjonen ? <br /> Handlingen
            er ikke reverserbar! <br />
            Plakat vil bli deaktivert umidelbart og abonnement opphører.
          </DialogContentText>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ textAlign: 'center' }}
          >
            <Grid item>
              <Button
                style={{
                  margin: 20,
                  backgroundColor: 'rgba(250,20,0,1)',
                }}
                variant="contained"
                onClick={() => setdialogBox({ status: false, id: '' })}
              >
                Avbryt
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{
                  margin: 20,
                }}
                color="primary"
                variant="contained"
                onClick={() => {
                  dispatchDelete(id);
                }}
              >
                Okay
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const DownloadTablePoster = async (data) => {
    setloading(true);
    var POSTER_URL = testing
      ? `http://localhost:5000/c/poster/small/${data.id}`
      : `/c/poster/small/${data.id}`;
    await axios
      .get(POSTER_URL, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let blob = new Blob([response.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
        setloading(false);
      });
  };

  const uploadFile = async (data, user, index) => {
    setloading(true);
    setFileSizeToBig(false);
    context.uploadFile(data, user, index).then((res) => {
      if (res == 'datasize') {
        setFileSizeToBig(true);
        setloading(false);
      } else {
        setloading(false);
      }
    });
  };

  const changeLayout = (data, value) => {
    context.changeLayout(data, value);
  };

  const ListItemLine = (props) => {
    const [edit, setedit] = React.useState(false);
    const [InputValue, setInputValue] = React.useState('');

    var FireField;
    const Icon = () => {
      switch (props.title) {
        case 'Navn':
          FireField = 'navn';
          return <PersonIcon />;
        case 'Telefon':
          FireField = 'telefon';
          return <PhoneIcon />;
        case 'Firmanavn':
          FireField = 'firmanavn';
          return <BusinessIcon />;
        case 'Adresse':
          FireField = 'adresse';
          return <LocationOnIcon />;
        case 'Beskrivelse':
          FireField = 'description';
          return <DescriptionIcon />;
        case 'Epost':
          FireField = 'epost';
          return <EmailIcon />;
        default:
          FireField = 'undefined';
          return <PersonIcon />;
      }
    };

    const DispatchChangeToDatabase = (fire, FireField, index) => {
      if (InputValue) {
        setloading(true);
        context.updateUserSubs(FireField, InputValue, fire.id, index);
      }
      setloading(false);
      setedit(false);
    };

    return (
      <ListItem key={props.title}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        {console.log(window.innerWidth)}
        {window.innerWidth > 850 ? (
          <ListItemText>{props.title}</ListItemText>
        ) : null}
        <div style={{ textAlign: 'center', marginRight: 40 }}>
          {edit ? (
            <Input
              placeholder={props.data}
              value={InputValue}
              onChange={(e) => setInputValue(e.target.value)}
            ></Input>
          ) : (
            <p
              style={{
                display: 'inline',
                textAlign: 'center',
                marginRight: 40,
              }}
            >
              {props.data}
            </p>
          )}
        </div>

        <ListItemAvatar style={{ position: 'absolute', right: 0 }}>
          <Avatar>
            {edit ? (
              <IconButton
                style={{
                  color: 'rgba(20,240,20,1)',
                  backgroundColor: 'rgba(0,60,0,1)',
                }}
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  DispatchChangeToDatabase(props.fire, FireField, props.index);
                }}
              >
                <ThumbUpIcon />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  setedit(true);
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Avatar>
        </ListItemAvatar>
      </ListItem>
    );
  };

  const SubsList = () => {
    const [expanded, setExpanded] = React.useState(false);
    const parentRef = React.useRef(null);

    var colorList = [];
    for (var i = 0; i < context.subscriptions.length; i++) {
      colorList.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    }

    return context.subscriptions.map((x, i) => {
      return (
        <Col
          className="ml-auto mr-auto"
          md="12"
          lg="12"
          style={{ marginTop: 10, marginBottom: 10 }}
          key={i}
        >
          <Card
            className={classes.root}
            style={!matches ? { padding: 0 } : null}
          >
            <CardActions disableSpacing>
              <ListItem alignItems="flex-start" onClick={() => {
                expanded[i]
                  ? setExpanded({ [i]: false })
                  : setExpanded({ [i]: true });
              }}>
                <ListItemAvatar>
                  <Avatar alt="logo" src={x.logo} />
                </ListItemAvatar>
                <ListItem alignItems="flex-start">
                  <h5>{x.firmanavn + " - " + x.description}</h5>
                </ListItem>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded[i],
                  })}
                  onClick={() => {
                    expanded[i]
                      ? setExpanded({ [i]: false })
                      : setExpanded({ [i]: true });
                  }}
                  aria-expanded={expanded[i]}
                  aria-label="show more"
                  label="Statestikk"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </ListItem>
            </CardActions>
            <Collapse in={expanded[i]} timeout="auto" unmountOnExit>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="recipe"
                    className={classes.avatar}
                    style={{ backgroundColor: colorList[i] }}
                  >
                    {x.firmanavn.charAt(0)}
                  </Avatar>
                }
                // action={
                //   <IconButton
                //     aria-label="settings"
                //     style={{ color: 'red' }}
                //     onClick={() => deleteSub(x.id)}
                //   >
                //     <DeleteForever />
                //   </IconButton>
                // }
                title={x.firmanavn}
                subheader={x.description}
              />

              <CardContent>
                <h5>
                  <a
                    href={`https://smittesporing.app/c/${x.id}`}
                    target="blank"
                  >{`https://smittesporing.app/c/${x.id}`}</a>
                </h5>

                <List component="nav" aria-label="main mailbox folders">
                  <ListItemLine title={'Navn'} data={x.navn} fire={x} index={i} />
                  <Divider variant="inset" component="li" />
                  <ListItemLine
                    title={'Telefon'}
                    data={x.telefon}
                    fire={x}
                    index={i}
                  />
                  <Divider variant="inset" component="li" />
                  <ListItemLine
                    title={'Firmanavn'}
                    data={x.firmanavn}
                    fire={x}
                    index={i}
                  />
                  <Divider variant="inset" component="li" />
                  <ListItemLine
                    title={'Adresse'}
                    data={x.adresse}
                    fire={x}
                    index={i}
                  />
                  <Divider variant="inset" component="li" />
                  <ListItemLine
                    title={'Beskrivelse'}
                    data={x.description}
                    fire={x}
                    index={i}
                  />
                  <Divider variant="inset" component="li" />
                  <ListItemLine
                    title={'Epost'}
                    data={x.epost}
                    fire={x}
                    index={i}
                  />
                  <Divider variant="inset" component="li" />
                </List>
              </CardContent>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ textAlign: 'center' }}
                >
                  <Grid item md={3} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Velg Layout
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={x.layout ? x.layout : layout}
                        onChange={(e) => {
                          setLayout(e.target.value);
                          changeLayout(x, e.target.value);
                        }}
                      >
                        <MenuItem value={'title'}>Firmanavn</MenuItem>
                        <MenuItem value={'description'}>Beskrivelse</MenuItem>
                        <MenuItem value={'all'}>
                          Firmanavn og Beskrivelse
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        Velg layout på registrerings portal
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() => DownloadPoster(x)}
                      className={classes.DashButtons}
                    >
                      <ArchiveIcon style={{ margin: 5 }} />
                      Last ned plakat
                    </Button>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() => DownloadTablePoster(x)}
                      className={classes.DashButtons}
                    >
                      <ArchiveIcon style={{ margin: 5 }} />
                      Last ned bordkort
                    </Button>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      variant="outlined"
                      component="label"
                      className={classes.DashButtons}
                    >
                      <PublishIcon style={{ margin: 5 }} />
                      Last opp logo
                      <input
                        type="file"
                        hidden
                        onChange={(e) => uploadFile(e.target.files[0], x, i)}
                      />
                    </Button>
                  </Grid>
                  {FileSizeToBig ? (
                    <Grid item xs={12}>
                      <p style={{ color: 'red' }}>
                        Fil størrelse for stor. Må ikke overstige 1 mb
                      </p>
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'center ', margin: '40px 0px 20px 0px' }}
                  >
                    {x.logo ? (
                      <img
                        src={x.logo}
                        style={{
                          maxHeight: '20vh',
                          maxWidth: '40vw',
                        }}
                      />
                    ) : null}
                  </Grid>
                </Grid>

                <CardContent ref={parentRef}>
                  <h5>Totalt registrerte de siste 10 dager : {x.statsum}</h5>
                  <StatsGraph data={x.stats} width={parentRef} />
                </CardContent>
              </CardContent>
            </Collapse>
          </Card>
        </Col>
      );
    });
  };

  const AddSubsForm = () => {
    const x = context.subscriptions[0];

    const [newSubsData, SetnewSubsData] = React.useState({
      adresse: '',
      dato: getDate,
      description: '',
      ehf: x.ehf,
      epost: '',
      firmanavn: '',
      idrett: false,
      layout: x.layout,
      logo: '',
      navn: '',
      news: false,
      orgnr: x.orgnr,
      postnummer: x.postnummer,
      poststed: x.poststed,
      rabattkode: x.rabattkode,
      telefon: '',
      useruid: x.useruid,
    });

    const [logo, setlogo] = React.useState({ blub: '', preview: '' });
    const [enableButton, setenableButton] = React.useState(true);
    const [FileSizeToBig, setFileSizeToBig] = React.useState(false);

    React.useEffect(() => {
      VerifyButton();
      return () => {
        setenableButton(true);
      };
    }, [newSubsData]);

    const VerifyButton = () => {
      if (
        !newSubsData['navn'] == '' &&
        !newSubsData['firmanavn'] == '' &&
        !newSubsData['telefon'] == '' &&
        !newSubsData['adresse'] == '' &&
        !newSubsData['epost'] == '' &&
        !newSubsData['description'] == ''
      ) {
        setenableButton(false);
      }
    };

    const uploadLogo = (e) => {
      if (typeof e === 'undefined') {
        return;
      } else {
        if (e.size > 1000000) {
          setFileSizeToBig(true);
        } else {
          setlogo({ blub: e, preview: URL.createObjectURL(e) });
          setFileSizeToBig(false);
        }
      }
    };

    const dispatchLogo = async () => {
      var list = newSubsData;
      return new Promise(async (resolve) => {
        context.uploadLogo(logo.blub).then((res) => {
          if (res == 'datasize') {
            setloading(false);
          } else {
            list['logo'] = res;
            resolve(list);
          }
        });
      });
    };

    const DispatchNewUser = async () => {
      setloading(true);
      if (logo.blub) {
        var res = await dispatchLogo();
        context.addUserSubs(res).then(async (respons) => {
          setaddingSubs(false);
          context.refreshList();
          setloading(false);
        });
      } else {
        context.addUserSubs(newSubsData).then(async (respons) => {
          setaddingSubs(false);
          context.refreshList();
          setloading(false);
        });
      }
    };

    return (
      <Col
        className="ml-auto mr-auto"
        md="12"
        lg="12"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Card className={classes.root}>
          <h4 style={{ textAlign: 'center' }}>Legg til lokasjon</h4>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {newSubsData.firmanavn.charAt(0)}
              </Avatar>
            }
            title={newSubsData.firmanavn}
            subheader={newSubsData.description}
          />

          <CardContent>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                {window.innerWidth > 850 ? (
                  <ListItemText>Navn</ListItemText>
                ) : null}
                <div style={{ textAlign: 'center', marginRight: 40 }}>
                  <Input
                    placeholder="navn"
                    onChange={(e) =>
                      SetnewSubsData({ ...newSubsData, navn: e.target.value })
                    }
                  ></Input>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                {window.innerWidth > 850 ? (
                  <ListItemText>Epost</ListItemText>
                ) : null}
                <div style={{ textAlign: 'center', marginRight: 40 }}>
                  <Input
                    placeholder="epost"
                    onChange={(e) =>
                      SetnewSubsData({ ...newSubsData, epost: e.target.value })
                    }
                  ></Input>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                {window.innerWidth > 850 ? (
                  <ListItemText>Telefon</ListItemText>
                ) : null}
                <div style={{ textAlign: 'center', marginRight: 40 }}>
                  <Input
                    placeholder="telefon"
                    onChange={(e) =>
                      SetnewSubsData({
                        ...newSubsData,
                        telefon: e.target.value,
                      })
                    }
                  ></Input>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                {window.innerWidth > 850 ? (
                  <ListItemText>Firmanavn</ListItemText>
                ) : null}
                <div style={{ textAlign: 'center', marginRight: 40 }}>
                  <Input
                    placeholder="firmanavn"
                    onChange={(e) =>
                      SetnewSubsData({
                        ...newSubsData,
                        firmanavn: e.target.value,
                      })
                    }
                  ></Input>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                {window.innerWidth > 850 ? (
                  <ListItemText>Adresse</ListItemText>
                ) : null}
                <div style={{ textAlign: 'center', marginRight: 40 }}>
                  <Input
                    placeholder="adresse"
                    onChange={(e) =>
                      SetnewSubsData({
                        ...newSubsData,
                        adresse: e.target.value,
                      })
                    }
                  ></Input>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                {window.innerWidth > 850 ? (
                  <ListItemText>Beskrivelse</ListItemText>
                ) : null}
                <div style={{ textAlign: 'center', marginRight: 40 }}>
                  <Input
                    placeholder="beskrivelse"
                    onChange={(e) =>
                      SetnewSubsData({
                        ...newSubsData,
                        description: e.target.value,
                      })
                    }
                  ></Input>
                </div>
              </ListItem>
              <Divider variant="inset" component="li" />
            </List>
          </CardContent>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              style={{ textAlign: 'center' }}
            >
              <Grid item md={3} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Velg Layout
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={x.layout ? x.layout : layout}
                    onChange={(e) => {
                      SetnewSubsData({
                        ...newSubsData,
                        layout: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value={'title'}>Firmanavn</MenuItem>
                    <MenuItem value={'description'}>Beskrivelse</MenuItem>
                    <MenuItem value={'all'}>Firmanavn og Beskrivelse</MenuItem>
                  </Select>
                  <FormHelperText>
                    Velg layout på registrerings portal
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={12}>
                <Button
                  variant="outlined"
                  component="label"
                  className={classes.DashButtons}
                >
                  <PublishIcon style={{ margin: 5 }} />
                  Last opp logo
                  <input
                    type="file"
                    hidden
                    onChange={(e) => uploadLogo(e.target.files[0])}
                  />
                </Button>
              </Grid>
              {FileSizeToBig ? (
                <Grid item xs={12}>
                  <p style={{ color: 'red' }}>
                    Fil størrelse for stor. Må ikke overstige 1 mb
                  </p>
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center ', margin: '40px 0px 20px 0px' }}
              >
                {logo.preview ? (
                  <img
                    src={logo.preview}
                    style={{
                      maxHeight: '20vh',
                      maxWidth: '40vw',
                      margin: 20,
                    }}
                  />
                ) : null}
              </Grid>

              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                style={{ textAlign: 'center' }}
              >
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setaddingSubs(false)}
                  >
                    Avbryt
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    disabled={enableButton}
                    color="primary"
                    onClick={() => {
                      DispatchNewUser();
                    }}
                  >
                    Legg til
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Col>
    );
  };

  const Oversikt = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <h3 style={{ textAlign: 'center' }}>
            Oversikt over dine tjenester hos Smittesporing.app
          </h3>
        </Grid>
      </Grid>
    );
  };

  const addLocation = () => {
    setaddingSubs(true);
  };

  const deleteSub = async (id) => {
    setdialogBox({ status: true, id: id });
  };
  const dispatchDelete = async () => {
    var id = dialogBox.id;
    setdialogBox({ status: false, id: '' });
    setloading(true);
    await context.DeleteSubs(id);
    setloading(false);
  };

  return (
    <AuthContext.Consumer>
      {(context) => (
        <>
          <FixedTransparentNavbar />
          <DeleteDialogBox />
          <div
            className="content"
            style={{
              backgroundImage: `url(${bg})`,
              minHeight: '100vh',
            }}
          >
            <Container style={matches ? { width: '100%' } : null}>
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      backgroundColor: '#EFF7F6',
                      marginTop: '20%',
                      marginBottom: '10%',
                      borderRadius: 10,
                    }}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <h1 style={{ padding: 20 }}>Dashboard</h1>
                      <h3>{context.user.email}</h3>
                    </div>
                    <TabContext value={value}>
                      <AppBar
                        position="static"
                        variant="fullWidth"
                        style={{
                          color: 'black',
                          backgroundColor: 'rgba(255,255,255,1)',
                        }}
                      >
                        <TabList onChange={handleChange} centered>
                          <Tab label="Kontoer" value="2" />
                          <Tab label="Databehandleravtale" value="3" />
                        </TabList>
                      </AppBar>
                      <TabPanel
                        value="2"
                        style={DashCardQuery()}
                        style={!matches ? { padding: 2 } : null}
                      >
                        {!addingSubs ? (
                          <Grid xs={12}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => addLocation()}
                              style={{ width: '100%' }}
                            >
                              Legg til lokasjon
                            </Button>
                          </Grid>
                        ) : (
                          <AddSubsForm />
                        )}
                        <SubsList />
                      </TabPanel>
                      <TabPanel
                        value="3"
                        style={DashCardQuery()}
                        style={!matches ? { padding: 2 } : null}
                      >
                        <Databehandler data={context.subscriptions[0]} />
                      </TabPanel>
                    </TabContext>
                  </div>
                </Col>
              </Row>
            </Container>
            {loading ? <LoadingSpinner /> : null}
          </div>
          <FooterBlackSocial />
        </>
      )}
    </AuthContext.Consumer>
  );
};
