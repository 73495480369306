import { Container, Row, Col } from 'reactstrap';

export const OfteStilte = () => {
  return (
    <Container>
      <Row>
        <Col
          className="mr-auto"
          sm="12"
          md="12"
          lg="12"
          style={{ marginTop: 50 }}
        >
          <h1>Ofte stilte spørsmål</h1>
        </Col>
        <Col sm="6">
          <ul style={{ listStyle: 'none', textAlign: 'initial' }}>
            <li>
              <span className="headText">
                Er det begrensning i antall QR-koder jeg kan skrive ut?
              </span>
              <br />
              Du kan printe ut så mange QR-kode plakater som du ønsker og
              benytte dem i dine lokaler,
              <br /> men du kan ikke henge opp samme plakat på en annen adresse.
              <br />
              Om du bruker plakaten på flere adresser, vil ikke
              smittesporingsteamet få riktig oversikt.
              <br />
              Hvis din bedrift er på flere steder, må du ha abonnement for hvert
              sted <br />
              Ta kontakt med oss for oppsett av flere lokasjoner.
            </li>
            <br />
            <li>
              <span className="headText">
                Kan jeg bruke samme telefon for innmelding av flere brukere ?
              </span>
              <br />
              Ja. Smittesporing.app bruker kun den informasjonen du sender til
              oss gjennom besøksregistreringen, vi tar ingen informasjon fra din
              telefon. <br />
              Du kan derfor fint registrere inn flere brukere med samme enhet.
            </li>
            <br />
            <li>
              <span className="headText">
                Må jeg håndtere sletting av personer selv?
              </span>
              <br />
              Smittesporing.app tar vare på opplysningene i 10 dager, etter
              dette blir all data automatisk slettet.
              <br /> Du trenger ikke foreta deg noe.
            </li>
            <br />
            {/* <li>
              <span className="headText">Kan jeg betale med faktura?</span>
              <br />
              Du kan betale med faktura eller kredittkort. Med faktura betaler
              du for 3 måneder på forskudd + et fakturagebyr. Med kredittkort
              trekkes kortet automatisk hver måned.
            </li>
            <br /> */}
          </ul>
        </Col>
        <Col sm="6">
          <ul style={{ listStyle: 'none', textAlign: 'initial' }}>
            <li>
              <span className="headText">
                Er det begrensning på antall personer?
              </span>
              <br />
              Nei, det er ingen begrensning på antall personer som kan
              registrere besøket hos deg.
            </li>
            <br />
            <li>
              <span className="headText">
                Kan Smittesporing.app huske registreringen min?
              </span>
              <br />
              Ja. Smittesporing.app lagrer en cookie på din enhet ved
              registrering.
              <br />
              Dette gjøres for å forbedre din brukeropplevelse, og brukes til å
              huske ditt siste besøk slik at du ikke trenger å skrive inn navn
              og telefonnummer for hver gang.
              <br />
              Om dette ikke fungerer på din enhet, kan det være at lagring av
              cookies er deaktivert.
              <br />
              Send oss gjerne en mail om du har problemer:{' '}
              <a href="mailto:post@flexboks.com">post@flexboks.com</a>
            </li>
            <br />
            <li>
              <span className="headText">Hvor lang bindingstid er det?</span>
              <br />
              Smittesporing.app har ingen bindingstid og du kan når som helst
              avslutte abonnementet ved å sende en mail til
              <br />
              <a href="mailto:avbestilling@smittesporing.app">
                {' '}
                avbestilling@smittesporing.app
              </a>
            </li>
            <br />
            <li>
              <span className="headText">Hvem varsler helsemyndigheter?</span>
              <br />
              Ved smitte vil helsemyndighetene kontakte oss for utlevering av
              sporingsdata.
              <br />
              Smittesporing.app kan kun på forespørsel fra myndighetene uthente
              og bistå med persondata.
              <br />
              Alle personopplysninger som blir overlevert til myndighetene vil
              utelukkende bli brukt til sporing ved et evt. smittetilfelle.
            </li>
            <br />
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
