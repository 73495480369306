import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';

import { fireAuth } from 'config/fire';

// screens
import { MainView } from 'Views/MainView/MainView';
import { Retningslinjer } from 'Views/Retningslinjer/Retningslinjer';
import { SignupPage } from 'Views/SignupPage/SignupPage';
import { AboutUs } from 'Views/AboutView/About';
import { ContactView } from 'Views/ContactView/ContactView';
import { ProductView } from 'Views/ProductView/ProductView';
import { ConfirmationScreen } from 'Views/ConfirmationScreen';
import { LoginPage } from 'Views/LoginPage/LoginPage';
import { MinsidePage } from 'Views/MinsidePage/MinsidePage';

import AuthContext from 'Context/AuthContext';

export const App = () => {
  const _USER_CONTEXT = React.useContext(AuthContext);

  React.useEffect(() => {
    fireAuth.onAuthStateChanged((userAuth) => {
      _USER_CONTEXT.setUserAuth(userAuth);
    });
  }, [fireAuth]);

  return (
    <div className="App">
      <Router basename="/">
        <Switch>
          <Route exact path="/">
            <MainView test="fuck YOU HOMO" />
          </Route>
          <Route path="/registrer">
            <SignupPage />
          </Route>
          <Route path="/retningslinjer">
            <Retningslinjer />
          </Route>
          <Route path="/about">
            <AboutUs />
          </Route>
          <Route path="/contact">
            <ContactView />
          </Route>
          <Route path="/product">
            <ProductView />
          </Route>
          <Route path="/confirmation">
            <ConfirmationScreen />
          </Route>
          <Route path="/databehandleravtale">
            <Retningslinjer />
          </Route>
          <Route path="/login">
            {_USER_CONTEXT.user ? <Redirect to="/minside" /> : <LoginPage />}
          </Route>
          <Route path={'/minside'}>
            {!_USER_CONTEXT.user ? <Redirect to="/login" /> : <MinsidePage />}
          </Route>
        </Switch>
      </Router>
    </div>
  );
};
