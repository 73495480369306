import React from 'react';
import AuthContext from '../Context/AuthContext';
import { fireStore } from 'config/fire';
import Axios from 'axios';
var _ = require('lodash');

const GlobalState = (props) => {
  var testing = 0;
  const [_AUTH, SET_AUTH] = React.useState(null);
  const [_SUBS, SET_SUBS] = React.useState([]);
  const [_STATS, SET_STATS] = React.useState();

  // Sleep function
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const setUserAuth = async (data) => {
    await SET_AUTH(data);
    await getSubscriptions(data);
  };

  const RefreshSubs = (data, id) => {
    return new Promise(async (resolve) => {
      var newData = data;
      newData['id'] = id.data;
      SET_SUBS((old) => [...old, newData]);
      resolve();
    });
  };

  const DeleteSubs = (id) => {
    return new Promise(async (resolve) => {
      try {
        var getFire = await fireStore
          .collection('smittesporing')
          .doc('kunder')
          .collection('info')
          .doc(id)
          .get()
          .then((doc) => {
            doc.ref.delete();
          });
      } catch (err) {
        console.log(err);
      }
      await refreshList();
      resolve();
    });
  };

  const refreshList = () => {
    SET_SUBS([]);
    var subs = [];
    return new Promise(async (resolve) => {
      try {
        var getFire = await fireStore
          .collection('smittesporing')
          .doc('kunder')
          .collection('info')
          .where('useruid', '==', _AUTH.uid)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              subs.push(doc.data());
            });
          });
      } catch (err) {
        console.log(err);
      }
      SET_SUBS(subs);
      resolve();
    });
  };

  const addUserSubs = async (data) => {
    var testing = 0;
    // AUTH HEADER AXIOS
    const AUTH_TOKEN = 'cffefd6a-01b2-11eb-adc1-0242ac120002';
    Axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

    var headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
    };
    var POST_URL = testing ? `http://localhost:5000/c/add/` : `/c/add/`;

    return new Promise(async (resolve) => {
      try {
        await Axios.post(POST_URL, data, { headers: headers }).then(
          (response) => {
            resolve(response);
          }
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  const getSubscriptions = async (data) => {
    var subs = [];
    if (data) {
      return new Promise(async (resolve) => {
        try {
          var getFire = await fireStore
            .collection('smittesporing')
            .doc('kunder')
            .collection('info')
            .where('useruid', '==', data.uid)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                subs.push(doc.data());
              });
            });
        } catch (err) {
          console.log(err);
        }
        SET_SUBS(subs);
        getStats(subs);
        resolve();
      });
    }
  };

  const updateUserSubs = (FireField, value, id, index) => {
    return new Promise(async (resolve) => {
      try {
        var updateFire = await fireStore
          .collection('smittesporing')
          .doc('kunder')
          .collection('info')
          .doc(id)
          .update({ [FireField]: value });
      } catch (err) {
        console.log(err);
      }
      let new_subs = [..._SUBS];
      new_subs[index][FireField] = value;
      SET_SUBS(new_subs);
      resolve();
    });
  };

  const changeLayout = (data, value) => {
    return new Promise(async (resolve) => {
      try {
        var updateFire = await fireStore
          .collection('smittesporing')
          .doc('kunder')
          .collection('info')
          .doc(data.id)
          .update({ layout: value });
      } catch (err) {
        console.log(err);
      }
      await getSubscriptions(_AUTH);
      resolve();
    });
  };

  const getStats = (data) => {
    var testing = 0;
    return new Promise((resolve) => {
      try {
        data.forEach(async (element, i) => {
          var URL = testing
            ? `http://localhost:5000/sporing/stats/${element.id}`
            : `/sporing/stats/${element.id}`;
          await Axios.get(URL).then((response) => {
            var sums = response.data;
            const arrSum = sums.reduce((a, b) => a + b.value, 0);
            element['stats'] = response.data;
            element['statsum'] = arrSum;
          });
        });

        resolve(SET_SUBS(data));
      } catch (err) {
        console.log(err);
        return;
      }
    });
  };

  const uploadLogo = async (data) => {
    var testing = 0;
    var UPLOAD_URL = testing ? 'http://localhost:5000/upload' : '/upload';
    return new Promise(async (resolve) => {
      if (data.size > 1000000) {
        resolve('datasize');
        return;
      }
      const submit = async () => {
        const headers = {
          'Content-Type': 'multipart/form-data',
          post: {
            'Content-Type': 'multipart/form-data',
          },
        };

        let localUri = data;

        let formData = new FormData();

        formData.append('file', localUri, localUri.name);

        try {
          const res = await Axios.post(UPLOAD_URL, formData, headers).then(
            async (res) => {
              resolve(res.data);
            }
          );
        } catch (err) {
          console.log(err);
          if (err.response.status === 500) {
            console.log('There was a problem with the server');
            resolve('There was a problem with the server');
          } else {
            console.log(err.response.data.msg);
          }
        }
      };
      submit();
    });
  };

  const uploadFile = async (data, user, index) => {
    testing = 0;
    var UPLOAD_URL = testing ? 'http://localhost:5000/upload' : '/upload';
    return new Promise(async (resolve) => {
      var firefield = 'logo';
      if (data.size > 1000000) {
        resolve('datasize');
        return;
      }
      const submit = async () => {
        const headers = {
          'Content-Type': 'multipart/form-data',
          post: {
            'Content-Type': 'multipart/form-data',
          },
        };

        let localUri = data;

        let formData = new FormData();

        formData.append('file', localUri, localUri.name);

        try {
          const res = await Axios.post(UPLOAD_URL, formData, headers).then(
            async (res) => {
              updateUserSubs(firefield, res.data, user.id, index);
              resolve(res.data);
            }
          );
        } catch (err) {
          console.log(err);
          if (err.response.status === 500) {
            console.log('There was a problem with the server');
            resolve('There was a problem with the server');
          } else {
            console.log(err.response.data.msg);
          }
        }
      };
      submit();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: _AUTH,
        subscriptions: _SUBS,
        stats: _STATS,
        setUserAuth: setUserAuth,
        getSubs: getSubscriptions,
        updateUserSubs: updateUserSubs,
        changeLayout: changeLayout,
        uploadFile: uploadFile,
        addUserSubs: addUserSubs,
        uploadLogo: uploadLogo,
        RefreshSubs: RefreshSubs,
        DeleteSubs: DeleteSubs,
        refreshList: refreshList,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default GlobalState;
