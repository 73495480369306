/*eslint-disable*/
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import { fireAuth } from 'config/fire';

import AuthContext from 'Context/AuthContext';

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
  NavLink,
} from 'reactstrap';

function FixedTransparentNavbar() {
  const _AUTH = React.useContext(AuthContext);

  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const signOut = () => {
    fireAuth.signOut();
    history.push('/login');
  };

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="navbar-absolute navbar-transparent" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              Smittesporing.app
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              Smittesporing.app, utviklet av Flexboks AS
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav style={{ marginLeft: 'auto' }} navbar>
              <NavItem active={window.location.pathname === '/'}>
                <NavLink onClick={(e) => e.preventDefault()}>
                  <Link to="/">Hjem</Link>
                </NavLink>
              </NavItem>
              <NavItem active={window.location.pathname === '/retningslinjer'}>
                <NavLink onClick={(e) => e.preventDefault()}>
                  <Link to="/retningslinjer">Retningslinjer</Link>
                </NavLink>
              </NavItem>

              {_AUTH.user ? (
                <NavItem active={window.location.pathname === '/minside'}>
                  <NavLink onClick={(e) => e.preventDefault()}>
                    <Link to="/minside">Min side</Link>
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem active={window.location.pathname === '/registrer'}>
                  <NavLink onClick={(e) => e.preventDefault()}>
                    <Link to="/registrer">Registrering</Link>
                  </NavLink>
                </NavItem>
              )}
              {_AUTH.user ? (
                <NavItem active={window.location.pathname === '/login'}>
                  <NavLink onClick={() => signOut()}>
                    <Link to="/">Log ut</Link>
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem active={window.location.pathname === '/login'}>
                  <NavLink onClick={(e) => e.preventDefault()}>
                    <Link to="/login">Log inn</Link>
                  </NavLink>
                </NavItem>
              )}

              <NavItem>
                <NavLink
                  href="https://www.facebook.com/flexboks"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square"></i>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default FixedTransparentNavbar;
