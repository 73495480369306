import React from 'react';
import axios from 'axios';
import FormData from 'form-data';
// reactstrap components
import {
  // Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';

import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import CloudUpload from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';

import bg from 'assets/img/Splash_2_std.jpg';

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

import Loader from 'react-loader-spinner';

import { useHistory, Link } from 'react-router-dom';

import brreg from 'brreg';

// core components
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js';

import FooterBlackSocial from 'components/Footers/FooterBlackSocial';

import { fireAuth } from 'config/fire';

export const SignupPage = () => {
  // TESTING
  var testing = 0;

  // HIDE NAV
  React.useEffect(() => {
    document.body.classList.add('signup-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('signup-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  let history = useHistory();

  // GET DATE
  var d = new Date();
  var getDate =
    String(d.getDate()) +
    '.' +
    String(d.getMonth() + 1) +
    '.' +
    String(d.getFullYear());

  // Local STATE
  const [inputList, setInputList] = React.useState({
    useruid: '',
    navn: '',
    orgnr: '',
    firmanavn: '',
    telefon: '',
    adresse: '',
    postnummer: '',
    poststed: '',
    epost: '',
    news: false,
    dato: getDate,
    ehf: false,
    logo: '',
    idrett: false,
    description: '',
    rabattkode: '',
  });

  const [verifyEmail, setverifyEmail] = React.useState('');
  const [loading, setloading] = React.useState(false);
  const [enableButton, setenableButton] = React.useState(true);
  const [checked, setchecked] = React.useState(false);
  const [brregInfo, setbrregInfo] = React.useState([]);
  const [SearchBreg, setSearchBreg] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const [upLoaded, setupLoaded] = React.useState(false);
  const [FileSizeToBig, setFileSizeToBig] = React.useState(false);
  const [visibleAlert, setVisibleAlert] = React.useState(false);
  const [AlertMessage, setAlertMessage] = React.useState('');

  // AUTH HEADER AXIOS
  const AUTH_TOKEN = 'cffefd6a-01b2-11eb-adc1-0242ac120002';
  axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

  var headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  };

  // Sleep function
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  var POST_URL = testing ? `http://localhost:5000/c/add/` : `/c/add/`;
  var UPLOAD_URL = testing ? 'http://localhost:5000/upload' : 'upload';

  // USE EFFECTS
  React.useEffect(() => {
    VerifyButton();
    return () => {
      setenableButton(true);
      setupLoaded(false);
    };
  }, [inputList, verifyEmail, checked]);

  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      SearchBrregFirm();
      UpdateState();
      if (SearchBreg == '') {
        ClearBrreg();
      }
    }
  }, [SearchBreg]);

  // STYLES

  const useStyles = makeStyles((theme) => ({
    root: {
      '&.Mui-focused': {
        '& .MuiInputAdornment-root': {
          '& .MuiButtonBase-root': {
            zIndex: '9999',
          },
        },
      },
      '&.MuiInput-root': {
        '& .MuiInputAdornment-root': {
          '& .MuiButtonBase-root': {
            // display: 'none',
          },
        },
      },
    },
    primaryColor: {
      textAlign: 'initial',
      marginLeft: 20,
      '& .MuiFormControlLabel-root': {
        color: 'black',
      },
    },
    input: {
      display: 'none',
    },
    Search: {
      backgroundColor: 'rgba(233, 212, 96, .1)',
    },
  }));

  const formLabelsTheme = createMuiTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  });

  const classes = useStyles();

  const SearchBrregFirm = () => {
    if (SearchBreg.length > 3) {
      const options = {
        query: SearchBreg,
      };
      brreg(options)
        .then((res) => {
          setbrregInfo(res.enhetsregisteret.data.entries);
        })
        .catch(console.error);
    }
  };

  const LoadingSpinner = () => {
    return (
      <div className="overlay">
        <Loader
          className="loader"
          type="ThreeDots"
          color="rgba(0,0,240,.6)"
          height="100"
          width="100"
        />
      </div>
    );
  };

  const VerifyButton = () => {
    if (
      !inputList['navn'] == '' &&
      (!inputList['idrett'] == true ? !inputList['orgnr'] == '' : true) &&
      !inputList['firmanavn'] == '' &&
      !inputList['telefon'] == '' &&
      !inputList['adresse'] == '' &&
      !inputList['postnummer'] == '' &&
      !inputList['poststed'] == '' &&
      !inputList['epost'] == '' &&
      inputList['epost'] == verifyEmail &&
      checked
    ) {
      setenableButton(false);
    }
  };

  const Createuuid = (length) => {
    var charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const confirmRegistration = async () => {
    setAlertMessage('');
    setVisibleAlert(false);
    setloading(true);

    const createFireUser = async () => {
      try {
        const { user } = await fireAuth.createUserWithEmailAndPassword(
          inputList.epost,
          Createuuid(10)
        );
        dispatchToServer(user.uid);
      } catch (err) {
        console.log(err);
        setloading(false);
        AlertM(err.code);
      }
    };

    if (testing == 0) {
      createFireUser();
    } else {
      dispatchToServer('UUID');
    }
  };

  const dispatchToServer = async (uid) => {
    var list = {
      ...inputList,
      useruid: uid,
    };
    try {
      await axios
        .post(POST_URL, list, { headers: headers })
        .then((response) => {
          setloading(false);
          history.push({
            pathname: '/confirmation',
            state: { id: response.data },
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  const uploadFile = async (data) => {
    if (data.size > 1000000) {
      setFileSizeToBig(true);
      return;
    }
    setFileSizeToBig(false);
    const submit = async () => {
      const headers = {
        'Content-Type': 'multipart/form-data',
        post: {
          'Content-Type': 'multipart/form-data',
        },
      };

      let localUri = data;

      let formData = new FormData();

      formData.append('file', localUri, localUri.name);

      try {
        const res = await axios
          .post(UPLOAD_URL, formData, headers)
          .then((res) => {
            console.log(res.data);
            setInputList({
              ...inputList,
              logo: res.data,
            });
            ProgressBar();
            console.log('finiish');
            setupLoaded(true);
          });
      } catch (err) {
        console.log(err);
        if (err.response.status === 500) {
          console.log('There was a problem with the server');
        } else {
          console.log(err.response.data.msg);
        }
      }
    };
    submit();
  };

  const ProgressBar = () => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);
  };

  const Clear = (props) => {
    return (
      <div>
        {inputList[props.value] ? (
          <InputAdornment position="end" className={classes.endAdornment}>
            <IconButton
              size="small"
              onClick={() =>
                setInputList({
                  ...inputList,
                  [props.value]: '',
                })
              }
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null}
      </div>
    );
  };
  const ClearVerify = (props) => {
    return (
      <div>
        {verifyEmail ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setverifyEmail('')}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ) : null}
      </div>
    );
  };

  const UpdateState = () => {
    var brreg = brregInfo;
    brreg.forEach((info) => {
      if (info.navn == SearchBreg || info.orgnr == SearchBreg) {
        setInputList({
          ...inputList,
          firmanavn: info.navn,
          orgnr: info.orgnr,
          telefon: info.tlf,
          adresse: info.forretningsadr,
          postnummer: info.forradrpostnr,
          poststed: info.forradrpoststed,
        });
      }
    });
  };

  const AlertM = (message) => {
    setVisibleAlert(true);
    if (message == 'auth/email-already-in-use') {
      setAlertMessage('Epost alerede i bruk');
    } else {
      setAlertMessage(message);
    }
  };

  const ClearBrreg = () => {
    console.log('CLEAR');
    setInputList({
      ...inputList,
      firmanavn: '',
      orgnr: '',
      telefon: '',
      adresse: '',
      postnummer: '',
      poststed: '',
    });
    setbrregInfo([]);
  };

  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper">
        <div className="page-header header-filter" filter-color="white">
          <div
            className="page-header-image"
            style={{
              backgroundImage: `url(${bg})`,
            }}
          ></div>

          <div className="content">
            <MuiThemeProvider theme={formLabelsTheme}>
              <Container style={{ top: 50 }}>
                <Row>
                  <Col className="ml-auto mr-auto" xs="12" md="6">
                    <div className="info info-horizontal ">
                      <div className="icon icon-info">
                        <i className="now-ui-icons media-2_sound-wave"></i>
                      </div>
                      <div className="description">
                        <h5 className="info-title" style={{ color: 'white' }}>
                          Smittesporing
                        </h5>
                        <p className="description">
                          Rask og enkel smittesporing for din bedrift.
                          <br />
                          Slik gjør du det:
                          <li>1. Registrer bedriften din her</li>
                          <li>
                            2. Heng opp plakaten du får på mail ved inngangen
                            og/eller andre synlige steder i ditt lokale
                          </li>
                          <li>
                            3. Dine besøkende registrerer seg enkelt og
                            effektivt ved å scanne QR-koden på plakaten.
                          </li>
                          Dersom din bedrift har flerer avdelinger,
                          <br />
                          må hver avdeling registreres som ny kunde
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal ">
                      <div className="icon icon-info">
                        <i className="now-ui-icons tech_laptop"></i>
                      </div>
                      <div className="description">
                        <h5 className="info-title" style={{ color: 'white' }}>
                          Sikkerhet
                        </h5>
                        <p className="description">
                          Vi behandler personopplysninger i tråd med den til
                          enhver tid gjeldende personopplysningslovgivningen i
                          Norge og EU.
                          <br />
                          Personopplysningene som samles inn slettes automatisk
                          etter 10 dager.
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal ">
                      <div className="icon icon-info">
                        <i className="now-ui-icons users_single-02"></i>
                      </div>
                      <div className="description">
                        <h5 className="info-title" style={{ color: 'white' }}>
                          Kostnader
                        </h5>
                        <p className="description">
                          Tjenesten koster 399,- per måned.
                          <br />
                          Ingen bindingstid.
                          <br />
                          Idrettslag og frivilige organisasjoner får bruke tjenesten gratis!
                        </p>
                      </div>
                    </div>
                    <Link to="/retningslinjer">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: 50, marginTop: 50 }}
                      >
                        Retningslinjer og Databehandleravtale
                      </Button>
                    </Link>
                  </Col>
                  <Col className="mr-auto" xs="12" md="6">
                    <Card>
                      <CardBody>
                        <CardTitle
                          className="text-center PrimaryTextColor"
                          tag="h2"
                          style={{ marginTop: 30, marginBottom: 50 }}
                        >
                          Registrer bedrift
                        </CardTitle>

                        <Form action="" className="form" method="">
                          <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="center"
                          >
                            <Grid
                              item
                              md={11}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <Autocomplete
                                freeSolo
                                className={classes.Search}
                                value={SearchBreg}
                                onClose={() => UpdateState()}
                                onSelect={(value) => {
                                  setSearchBreg(value.target.value);
                                }}
                                options={brregInfo.map((option) =>
                                  SearchBreg.match(/^[0-9]+$/)
                                    ? option.orgnr
                                    : option.navn
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={
                                      'Søk bedrift - Org nr eller Firmanavn'
                                    }
                                    className="inputField"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                required
                                fullWidth
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="navn" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.navn}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    navn: e.target.value,
                                  })
                                }
                                label={'Navn'}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                required
                                fullWidth
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="telefon" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.telefon}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (re.test(e.target.value)) {
                                    setInputList({
                                      ...inputList,
                                      telefon: e.target.value,
                                    });
                                  }
                                }}
                                label={'Telefon'}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                required
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="firmanavn" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.firmanavn}
                                onChange={(e) => {
                                  setInputList({
                                    ...inputList,
                                    firmanavn: e.target.value,
                                  });
                                }}
                                label={'Firmanavn/Forening'}
                              />
                            </Grid>

                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                required={inputList.idrett ? false : true}
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="orgnr" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.orgnr}
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (re.test(e.target.value)) {
                                    setInputList({
                                      ...inputList,
                                      orgnr: e.target.value,
                                    });
                                  }
                                }}
                                label={'Org nr'}
                              />
                            </Grid>

                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                required
                                fullWidth
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="adresse" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.adresse}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    adresse: e.target.value,
                                  })
                                }
                                label={'Adresse'}
                              />
                            </Grid>

                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="description" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.description}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    description: e.target.value,
                                  })
                                }
                                label={'Beskrivelse (vises på plakat)'}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                required
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="postnummer" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.postnummer}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    postnummer: e.target.value,
                                  })
                                }
                                label={'Postnummer'}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                required
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="poststed" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.poststed}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    poststed: e.target.value,
                                  })
                                }
                                label={'Poststed'}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                required
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="epost" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.epost}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    epost: e.target.value,
                                  })
                                }
                                label={'Epost'}
                              />
                            </Grid>

                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                required
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <ClearVerify />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={verifyEmail}
                                onChange={(e) => setverifyEmail(e.target.value)}
                                label={'Gjenta Epost'}
                              />
                            </Grid>
                            <Grid
                              item
                              md={5}
                              xs={12}
                              style={{ marginBottom: 10 }}
                            >
                              <TextField
                                fullWidth
                                className="inputField"
                                InputProps={{
                                  classes: {
                                    root: classes.root,
                                    underline: classes.underline,
                                  },
                                  endAdornment: <Clear value="rabattkode" />,
                                }}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.label,
                                    focused: classes.focused,
                                  },
                                }}
                                value={inputList.rabattkode}
                                onChange={(e) =>
                                  setInputList({
                                    ...inputList,
                                    rabattkode: e.target.value,
                                  })
                                }
                                label={'Rabatt kode'}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ marginBottom: 20, color: 'red' }}
                            >
                              {verifyEmail.length > 0 &&
                                inputList['epost'] != verifyEmail && (
                                  <p>Email stemmer ikke </p>
                                )}
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              style={{ margin: 20, color: 'red' }}
                            >
                              <Grid item md={6} xs={12}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  style={
                                    progress == 100
                                      ? {
                                          borderColor: 'rgba(34,139,34,1)',
                                          color: 'rgba(34,139,34,1)',
                                        }
                                      : null
                                  }
                                >
                                  <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="icon-button-file"
                                    type="file"
                                    onChange={(e) =>
                                      uploadFile(e.target.files[0])
                                    }
                                  />

                                  <label
                                    htmlFor="icon-button-file"
                                    style={{ marginBottom: 0 }}
                                  >
                                    <AddAPhoto style={{ marginRight: 5 }} />

                                    {progress == 100
                                      ? 'Logo lagt til'
                                      : 'Legg til Logo'}
                                  </label>
                                </Button>
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: 30 }}>
                                {FileSizeToBig ? (
                                  <p style={{ color: 'red' }}>
                                    Fil størrelse for stor. Må ikke overstige 1
                                    mb
                                  </p>
                                ) : null}
                                {progress > 0 && progress < 100 ? (
                                  <div className={classes.root}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={progress}
                                    />
                                  </div>
                                ) : null}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} className={classes.primaryColor}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked}
                                    onChange={(event) =>
                                      setchecked(event.target.checked)
                                    }
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                }
                                label={
                                  <span style={{ color: 'black' }}>
                                    <span
                                      style={{
                                        color: 'red',
                                        position: 'relative',
                                        bottom: '5px',
                                        right: '10px',
                                      }}
                                    >
                                      *
                                    </span>
                                    Bekreft at du har lest retningslinjene og
                                    databehandleravtale
                                  </span>
                                }
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.primaryColor}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={inputList['idrett']}
                                    onChange={(event) =>
                                      setInputList({
                                        ...inputList,
                                        idrett: event.target.checked,
                                      })
                                    }
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                }
                                label="Idrettslag/Forening"
                              />
                            </Grid>
                            <Grid item xs={12} className={classes.primaryColor}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={inputList['ehf']}
                                    onChange={(event) =>
                                      setInputList({
                                        ...inputList,
                                        ehf: event.target.checked,
                                      })
                                    }
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                }
                                label="Motta faktura på EHF"
                              />
                            </Grid>

                            <Grid item xs={12} className={classes.primaryColor}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={inputList['news']}
                                    onChange={(event) =>
                                      setInputList({
                                        ...inputList,
                                        news: event.target.checked,
                                      })
                                    }
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                  />
                                }
                                label="Godkjenn at vi kan sende nyhetsbrev"
                              />
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <Alert
                                color="danger"
                                isOpen={visibleAlert}
                                toggle={() => {
                                  setVisibleAlert(false);
                                }}
                                fade
                              >
                                {AlertMessage}
                              </Alert>
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              md={8}
                              style={{ marginTop: 50, marginBottom: 50 }}
                            >
                              <Button
                                onClick={confirmRegistration}
                                disabled={enableButton}
                                style={{ width: '100%', height: 45 }}
                                variant="contained"
                                color="primary"
                              >
                                Registrer
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </MuiThemeProvider>
          </div>
          {loading ? <LoadingSpinner /> : null}
        </div>
        <FooterBlackSocial />
      </div>
    </>
  );
};
