import firebase from 'firebase';

import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBOxFlPCKUCMjWoTssmcnvVygoK8Rtq3xg',
  authDomain: 'smittesporing-ce3ba.firebaseapp.com',
  databaseURL: 'https://smittesporing-ce3ba.firebaseio.com',
  projectId: 'smittesporing-ce3ba',
  storageBucket: 'smittesporing-ce3ba.appspot.com',
  messagingSenderId: '939962254980',
  appId: '1:939962254980:web:007cf3a77b93ec3cb764d1',
  measurementId: 'G-81XJDQKEN7',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const fireAuth = firebaseApp.auth();
export const fireStore = firebaseApp.firestore();
