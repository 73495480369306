import { Container, Row, Col } from 'reactstrap';
import './databehandler.css';

export const Databehandler = () => {
  return (
    <Container
      style={{ marginTop: 50, backgroundColor: 'white', borderRadius: 10 }}
    >
      <Row>
        <Col
          className="mr-auto"
          sm="12"
          md="12"
          lg="12"
          style={{ marginTop: 50 }}
        >
          <h1 style={{ overflowWrap: 'break-word', textAlign: 'center' }}>
            Databehandleravtale
          </h1>
        </Col>
        <Col className="mr-auto" sm="12" md="12" lg="12">
          <div className="databehandler">
            <p>
              I henhold til gjeldende lover og regler om personvern, inklusive
              personopplysningsforskriften og GDPR (EUs personvernforordning
              2016/679) er følgende avtale inngått
            </p>
            <p>mellom</p>
            <br />
            <p>
              Flexboks AS (smittesporing.app) <br />
              Org. nr. 925 595 128 <br />
              Petter Dassgt. 3 <br />
              8656 Mosjøen
              <br /> Norge
              <br />
              <br />
              heretter "Databehandler"
            </p>
            <p>og</p>
            <br />
            <p>
              firmnavn
              <br />
              Org. nr. Orgnr <br />
              Adresse <br />
              Postnummer, Poststed <br />
              Norge
            </p>
            <p>heretter "Behandlingsansvarlig"</p>
            <br />
            <p>
              hver omtalt som en "Part" eller sammen som "Partene", har avtalt
              de følgende standard kontraktsbestemmelser
              (Kontraktsbestemmelsene) for å oppfylle kravene i GDPR og for vern
              av den registrertes rettigheter.
            </p>
            <h4>1. Bakgrunn</h4>
            <p>
              Partene er enige om at Databehandleren skal foreta behandling av
              personopplysninger for Behandlingsansvarlig i henhold til avtale
              om bruk og tilgang til av Smittesporing.app ("Tjenesten"). Denne
              avtalen ("Avtalen") regulerer rettigheter og plikter i forbindelse
              med Databehandlerens behandling av personopplysninger på vegne av
              Behandlingsansvarlig. Avtalen skal sikre at personopplysningene
              behandles i samsvar med gjeldende personvernlovgivning
              ("Personvernregelverket"), herunder Europaparlaments- og
              rådsforordning (EU) 2016/679 av 27. april 2016
              ("Personvernforordningen"). Begreper benyttet i Avtalen skal
              forstås på samme måte som i Personvernregelverket.
            </p>
            <h4>2. Behandlingens formål og rammer</h4>
            <p>
              Formålet med behandlingen, varigheten av behandlingen,
              behandlingens art, de typer personopplysninger som skal behandles
              og kategorier av registrerte følger av vedlegg A til Avtalen.
              Databehandleren skal kun behandle personopplysningene i henhold
              til dokumenterte instrukser fra Behandlingsansvarlige og for øvrig
              i tråd med denne Avtalen. Dersom Databehandleren er rettslig
              forpliktet til å behandle personopplysningene på annen måte, for
              eksempel ved at han plikter å utlevere opplysningene til andre,
              skal Databehandleren varsle Behandlingsansvarlig før slik
              behandling foretas, med mindre viktige samfunnsinteresser tilsier
              at slikt varsel ikke kan finne sted. Databehandleren skal straks
              underrette Behandlingsansvarlig dersom Databehandleren mener en
              instruks fra Behandlingsansvarlig er i strid med
              Personvernregelverket.
            </p>
            <h4>3. Geografisk område</h4>
            <p>
              Databehandleren skal kun behandle personopplysningene innenfor
              EØS-området og kan ikke overføre opplysningene til andre land, med
              mindre dette er skriftlig og uttrykkelig avtalt med
              Behandlingsansvarlig.
            </p>
            <h4>4. Vurdering av personvernkonsekvenser</h4>
            <p>
              Databehandleren skal bistå Behandlingsansvarlig med å vurdere
              personvernkonsekvenser av den behandlingen Databehandleren skal
              foreta, og med eventuelle forhåndsdrøftinger med Datatilsynet, jf.
              Personvernforordningens Artikkel 35 og 36.
            </p>
            <h4>5. Personopplysningssikkerhet</h4>
            <p>
              Databehandleren skal gjennomføre tekniske og organisatoriske
              tiltak som er nødvendig for å oppnå et forsvarlig sikkerhetsnivå i
              tråd med Personvernregelverket, herunder særlig
              Personvernforordningens Artikkel 32. Databehandleren skal også
              bistå Behandlingsansvarlig med å gjennomføre slike tiltak.
              Databehandleren skal dokumentere sine tiltak, og dokumentasjonen
              skal være tilgjengelig på Behandlingsansvarliges forespørsel.
            </p>
            <h4>6. Brudd på personopplysningssikkerheten</h4>
            <p>
              Dersom Databehandleren får kjennskap til et brudd på
              personopplysningssikkerheten skal han uten ugrunnet opphold varsle
              den Behandlingsansvarlige. Varselet skal overholde de krav som
              fremgår av Personvernforordningens Artikkel 33 nr. 2 og 3, og skal
              for øvrig inneholde all relevant informasjon knyttet til bruddet
              som Databehandler har kjennskap til. Dersom Databehandleren senere
              blir kjent med ytterligere forhold knyttet til bruddet, skal han
              uten ugrunnet opphold ettersende informasjon om dette til
              Behandlingsansvarlig. Eventuell melding til Datatilsynet og/eller
              den registrerte skal skje gjennom Behandlingsansvarlig, med mindre
              annet er spesifikt avtalt mellom Partene. Databehandleren skal
              bistå Behandlingsansvarlig med å oppfylle de krav som stilles til
              Behandlingsansvarliges håndtering av brudd på
              personvernsikkerheten.
            </p>
            <h4>7. Henvendelser fra de registrerte</h4>
            <p>
              Databehandleren skal uten ugrunnet opphold varsle
              Behandlingsansvarlig om eventuelle henvendelser fra de
              registrerte. Databehandleren skal bistå Behandlingsansvarlig med å
              besvare henvendelser vedrørende de registrertes rettigheter etter
              Personvernforordningens kapittel III. Databehandleren skal ikke
              selv besvare henvendelser direkte, med mindre dette er spesifikt
              avtalt med Behandlingsansvarlig.
            </p>
            <h4>8. Bruk av underleverandør</h4>
            <p>
              Databehandleren skal ikke engasjere en underleverandør uten at det
              på forhånd er innhentet særlig eller generell skriftlig tillatelse
              til dette fra Behandlingsansvarlig. Ved en generell skriftlig
              tillatelse skal Databehandler underrette Behandlingsansvarlig om
              eventuelle planer om å legge til eller skifte ut eksisterende
              underleverandører, og dermed gi Behandlingsansvarlig mulighet til
              å motsette seg slike endringer. Databehandlers underleverandører
              ved inngåelse av denne avtalen er listet opp i vedlegg B.
              Databehandleren skal inngå egne skriftlige databehandleravtaler
              med sine underleverandører som pålegger underleverandøren de samme
              avtale- og lovmessige forpliktelsene som Databehandleren er
              underlagt i henhold til denne Avtalen. Behandlingsansvarlig har
              rett til innsyn i disse avtalene. Databehandleren er ansvarlig for
              at underleverandøren oppfyller sine forpliktelser knyttet til
              behandlingen av Behandlingsansvarliges personopplysninger.
            </p>
            <h4>9. Revisjon</h4>
            <p>
              Databehandleren skal gi Behandlingsansvarlig tilgang til
              dokumentasjon og informasjon som er nødvendig for å påvise
              etterlevelse av forpliktelsene fastsatt i denne Avtalen, og for at
              Behandlingsansvarlig skal kunne gjennomføre revisjoner. Revisjoner
              kan blant annet omfatte stedlig inspeksjon og evaluering av
              systemer, organisering og sikkerhetstiltak, samt bruk av
              underleverandører. Revisjoner kan utføres av Behandlingsansvarlig
              selv eller av en uavhengig tredjepart. Alle kostnader i
              forbindelse med revisjon dekkes av Behandlingsansvarlig. Dette
              inkluderer kostnader til den uavhengige tredjepart, kostnader
              Databehandler påføres i form av benyttet arbeidstid, materielle
              kostander og andre kostnader som følger av revisjon.
            </p>
            <h4>10. Konfidensialitet</h4>
            <p>
              Partene har taushetsplikt om informasjon knyttet til den annen
              Part som mottas i forbindelse med gjennomføringen av denne
              Avtalen, herunder Behandlingsansvarliges personopplysninger.
              Taushetsplikten gjelder også etter Avtalens opphør.
              Databehandleren kan bare gi tilgang til personopplysninger som
              behandles på den behandlingsansvarliges vegne til personer
              underlagt databehandlerens instruksjonsmyndighet som har
              forpliktet seg til konfidensialitet eller er underlagt passende
              lovbestemt taushetsplikt, og bare i det nødvendige omfang. Partene
              skal påse at ansatte og underleverandører som er involvert i
              gjennomføringen av denne Avtalen er underlagt tilsvarende
              taushetsplikt gjennom avtale eller lov.
            </p>
            <h4>11. Erstatningsansvar</h4>
            <p>
              Databehandleren er ansvarlig for skade som skyldes at
              Databehandleren har unnlatt å oppfylle sine forpliktelser etter
              Avtalen eller Personvernregelverket, eller for øvrig har opptrådt
              utenfor eller i strid med lovlige instrukser fra
              Behandlingsansvarlig. Dersom Behandlingsansvarlig har vært direkte
              involvert i den skadevoldende behandlingen har Databehandleren
              rett til å kreve tilbake en forholdsmessig andel av en eventuell
              erstatningsutbetaling, jf. Personvernforordningens Artikkel 82.
            </p>
            <h4>12. Avtalens varighet</h4>
            <p>
              Denne Avtalen trer i kraft ved opprettelse av konto hos
              Databehandlers hjemmeside eller signering av egen hovedavtale og
              gjelder så lenge Databehandleren behandler personopplysninger på
              vegne av Behandlingsansvarlig, som regulert gjennom underliggende
              avtale med Behandlingsansvarlig. Ved brudd på Avtalen eller
              Personvernregelverket kan Behandlingsansvarlig pålegge
              Databehandleren å stoppe den videre behandlingen av
              personopplysninger med umiddelbar virkning. Ved Avtalens opphør
              skal Databehandleren på Behandlingsansvarliges instruks enten
              tilbakelevere eller slette/ destruere alle personopplysninger han
              har behandlet på vegne av Behandlingsansvarlig, med mindre videre
              behandling er lovpålagt. Databehandleren skal innen rimelig tid
              skriftlig dokumentere at eventuell sletting/ destruksjon er
              foretatt i henhold til Behandlingsansvarliges instruks. Ved
              Avtalens opphør skal Databehandlerens tilgang til
              Behandlingsansvarliges systemer stenges. Databehandleren skal i
              nødvendig omfang bistå Behandlingsansvarlig med gjennomføringen av
              dette. Behandlingsansvarlig dekker alle kostnader i forbindelse
              med tilbakelevering og sletting av personopplysninger som er
              omfattet av denne avtalen.
            </p>
            <h4>13. Endringer</h4>
            <p>
              Partene skal revidere Avtalen ved behov for tilpasninger til
              preseptorisk lovgivning og tolkninger av Personvernforordningen.
            </p>
            <h4>14. Verneting</h4>
            <p>
              Denne Avtalen er underlagt norsk rett og Partene vedtar
              Databehandlerens hjemting som verneting, Alstahaug tingrett. Dette
              gjelder også etter opphør av Avtalen. Denne Avtalen er i 2 - to
              eksemplarer, hvorav Partene har hvert sitt eksemplar.
              <br /> Avtale er inngått ved registrering 'DATO for registrering'
            </p>
            <p>
              <br /> <br />
              For Behandlingsansvarlig:
              <br />
              Firmanavn
              <br />
              <br />
              ___________________________
              <br />
              <br />
              For Databehandler:
              <br />
              Flexboks AS
              <br />
              <br />
              ___________________________
            </p>
            <h3>Vedlegg A</h3>
            <p>
              <span id="subHead">Formålet med behandlingen</span> <br />
              Formålet med behandlingen er å levere de tjenester som følger av
              avtaler inngått mellom partene for tilgang til Tjenesten.
              <br />
              Tjenesten leverer besøksregistrering for å forbedre smittesporing
              dersom smitte oppdages hos Behandlingsansvarlige.
            </p>
            <p>
              <br />
              <span id="subHead">Varigheten av behandlingen</span> <br />
              Databehandleren skal kun behandle personopplysningene i den grad
              det anses som nødvendig for at Databehandler skal kunne levere
              Tjenesten.
              <br /> Personopplysninger knyttet til gjesteregistrering lagres i
              maksimalt 10 dager før de automatisk slettes.
              <br /> Personopplysninger knyttet til Behandlingsansvarliges
              ansatte lagres inntil 30 dager etter avtalens opphør.
            </p>
            <p>
              <br />
              <span id="subHead">Behandlingens art</span> <br />
              Behandlingen går ut på at Databehandler yter tjenester til
              Behandlingsansvarlig herunder lagring, backup av
              personopplysninger for levering av Tjenesten.
              <br /> De lagrede opplysningene vil kun utleveres til
              Behandlingsansvarliges kommunes og/eller bydels smittesporingsteam
              for å bedre arbeidet med smittesporing.
            </p>
            <p>
              <span id="subHead">
                Typer personopplysninger som skal behandles
              </span>
              <br />
              Behandlingen vil kunne omfatte en type opplysninger;
              <br />
              personopplysninger knyttet til Behandlingsansvarliges gjester.
              <br /> For Behandlingsansvarliges gjester lagres: navn og
              mobilnummer sammen med tidspunkt knyttet til
              Behandlingsansvarliges registrerte adresse.
            </p>
            <p>
              <span id="subHead">Kategorier av registrerte</span> <br />
              Besøkende i Behandlingsansvarliges lokaler.
            </p>
            <br /> <br />
            <h3>Vedlegg B</h3>
            <p>
              <span id="subHead">
                Underleverandører ved inngåelse av Avtalen
              </span>
              <br />
              Underleverandører ved inngåelse av Avtalen Databehandler benytter
              ved kontraktinngåelse følgende underleverandører:
              <br /> <br />
              <div style={{ textAlign: 'center' }}>
                <table className="tg">
                  <thead>
                    <tr>
                      <th className="tg-0lax">Firma</th>
                      <th className="tg-0lax">Land</th>
                      <th className="tg-0lax">Beskrivelse av behandling</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="tg-0lax">PRO ISP</td>
                      <td className="tg-0lax">NORGE</td>
                      <td className="tg-0lax">SERVER TJENESTER OG HOSTING</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br /> <br />
              Databehandler har ved Avtaleinngåelse spesifikk tillatelse til å
              anvende ovennevnte underleverandører til den behandling
              spesifisert her. Databehandler kan ikke - uten spesifikk og
              skriftlig tillatelse - anvende den enkelte underleverandør til
              annen behandling enn avtale eller la en annen underleverandør
              foreta den beskrevne behandlingen.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
