import { Container, Row, Col } from 'reactstrap';
import './Retningslinjer.css';

export const RetningsLinjerKunder = () => {
  return (
    <Container>
      <Row>
        <Col
          className="mr-auto"
          sm="12"
          md="12"
          lg="12"
          style={{ marginTop: 50 }}
        >
          <h1 style={{ overflowWrap: 'break-word' }}>
            Personvernerklæring kunder av smittesporing.app
          </h1>
        </Col>
        <Col className="mr-auto" sm="12" md="12" lg="12">
          <div className="retningslinjer">
            <h5>1. Generelle vilkår</h5>
            <br />
            <br />
            <li>
              1.1. Flexboks AS er en teknologibedrift som leverer IT-løsninger,
              herunder løsningen smittesporing.app. Flexboks AS er heretter
              benevnt "Flexboks", "vi", "vår" og "oss".
            </li>
            <br />
            <li>
              1.2. Flexboks har en klar politikk når det gjelder behandling av
              de personopplysninger som våre kunder og andre brukere av våre
              løsninger overlater til oss. Vi behandler alltid slike
              opplysninger ansvarsfullt, med respekt for ditt privatliv og
              naturligvis i overensstemmelse med lovgivningen på området,
              herunder personopplysningsloven. Kunder og brukere er heretter
              benevnt "du", "deg" og "dine".
            </li>
            <br />
            <li>
              1.3. Vi behandler dine personopplysninger i overensstemmelse med
              den til enhver tid gjeldende personopplysningslovgivning i Norge
              og EU, samt annen gjeldende lovgivning og/eller i overensstemmelse
              med eventuelt samtykke som du har gitt til Flexboks.
            </li>
            <br />
            <li>
              1.4. Nedenfor kan du lese detaljert om de opplysninger vi samler
              inn, til hvilke formål vi bruker dem, hvordan vi behandler
              opplysningene, hvem som har tilgang til opplysningene, dine
              rettigheter og hvem du kan kontakte vedrørende de innsamlede
              opplysningene. Vi benytter kun de opplysninger du selv har gitt
              oss og/eller ved at du benytter våre løsninger. I alle slike
              tilfeller aksepterer du vår personvernerklæring, herunder de
              angitte vilkår og betingelser som til enhver tid gjelder for
              behandling av dine personopplysninger.
            </li>
            <br />
            <li>
              1.5. Vi oppdaterer og endrer løpende vår personvernerklæring, slik
              at den til enhver tid er tilpasset lovgivningen og den
              teknologiske utviklingen. Ved vesentlige endringer gir vi deg
              beskjed i form av en synlig melding på smittesporing.app.
            </li>
            <br />
            <li>
              1.6. Dersom du vil vite mer om reglene for lovlig behandling av
              personopplysninger finner du mer informasjon på Datatilsynets
              hjemmeside, www.datatilsynet.no.
            </li>
            <br />
            <li>
              1.7. Dersom du har ytterligere spørsmål vedrørende de
              personopplysninger som Flexboks behandler når det gjelder deg, kan
              du kontakte post@flexboks.com.
            </li>
            <br />
            <li>
              1.8. Flexboks er dataansvarlig i forhold til behandling av dine
              personopplysninger og kan alltid kontaktes på følgende måte:
              Flexboks AS, Org.-nr. 925 595 128, Petter Dass gate 3, 8656
              Mosjøen, mob: 993 57 299, epost: post@flexboks.com{' '}
            </li>
            <br />
            <h5>2. Hvilke personopplysninger samler Flexboks inn?</h5>
            <br />
            <br />
            <li>2.1. Hva er personopplysninger?</li>
            <br />
            <li>
              2.1.1. Personopplysninger er opplysninger som direkte eller
              indirekte har tilknytning til en fysisk person. Eksempler på
              personopplysninger er navn, adresse, telefonnummer og e-post
              adresse.
            </li>
            <br />
            <li>
              2.2. Hvilke personopplysninger (heretter "opplysninger")
              samler/behandler Flexboks?
            </li>
            <br />
            <li>
              2.2.1. Flexboks samler inn opplysninger i forbindelse med
              markedsføring, innkjøp og levering av våre tjenester og produkter.
              Dette skjer for eksempel når du oppretter en profil i forbindelse
              med kjøp av våre tjenester og produkter, eller når du abonnerer på
              våre nyhetsbrev eller ber oss om å kontakte deg.
            </li>
            <br />
            <li>
              2.2.2. Vi samler bare opplysninger som du gir til oss via vårt
              nettsted eller telefon i forbindelse med ovennevnte. Dette
              inkluderer for eksempel navn, e-post adresse, telefon
              nummer/mobilnummer, firmanavn, fødselsdato, samt annen informasjon
              i forbindelse med bruken av vår nettside.
            </li>
            <br />
            <li>
              2.2.3. Du kan lese mer om bruk av våre cookies
              (informasjonskapsler) nedenfor.
            </li>
            <br />
            <h5>3. Til hvilke formål bruker Flexboks dine opplysninger?</h5>
            <br />
            <br />
            <li>
              <br />
              3.1. Flexboks behandler bare opplysninger i henhold til gjeldende
              lov. Vi bruker opplysninger som du har gitt til oss til følgende
              formål:
            </li>
            <br />
            <li>
              1) Til levering og overholdelse av de tjenester som du og/eller
              din virksomhet har meldt seg på og/eller de tjenester og produkt
              som du og/eller din virksomhet har kjøpt.
            </li>
            <br />
            <li>
              2) Dersom det oppstår en covid-19 relatert situasjon, vil vi bruke
              dine opplysninger til å bistå smittesporingsteamet for å begrense
              spredningen av covid-19.
            </li>
            <br />
            <li>
              3) Vi bruker din e-post adresse til markedsføring av våre
              tjenester og produkter.
            </li>
            <br />
            <li>
              4) For utsendelse av elektroniske nyhetsbrev til din e-post
              adresse.
            </li>
            <br />
            <li>5) For utsendelse av tilbud.</li>
            <br />
            <li>
              3.2. Det rettslige grunnlaget for vår behandling av opplysningene
              er vilkårene og betingelsene beskrevet i denne
              personvernserklæringen. Vi behandler derfor opplysninger som er
              nødvendige for å oppfylle den avtale som du og/eller din bedrift
              har inngått med Flexboks, eller for gjennomføring av eventuelle
              tiltak du ber oss om å utføre innen avtalen inngås. Vår behandling
              av opplysninger kan også forekomme dersom det er nødvendig for oss
              å forfølge en legitim interesse for behandling av informasjonen,
              for eksempel ved direkte elektronisk markedsføring av våre
              tjenester og produkter. Til slutt kan vi kun behandle opplysninger
              i samsvar med ditt samtykke til én eller flere bestemte formål.
            </li>
            <br />
            <h5>4. Hvordan oppbevarer Flexboks dine opplysninger?</h5>
            <br />
            <br />
            <li>
              4.1. Vi tar godt vare på opplysninger du gir oss om deg selv. Fra
              det øyeblikket vi mottar opplysninger kan du være trygg på at vi
              behandler det konfidensielt, og med sikkerhet slik loven krever.
            </li>
            <br />
            <li>
              4.2. Flexboks lagrer opplysninger du har gitt til oss så lenge den
              har relevans i forhold til de formålene vi har nevnt ovenfor.
              Dette betyr at vi lagrer dine opplysninger så lenge det er et
              kontraktsforhold mellom deg og/eller ditt firma og oss.
            </li>
            <br />
            <li>
              4.3. Vi har plikt til å sikre at dine opplysninger er korrekt og
              oppdatert. Fordi vår behandling av opplysningene også er avhengig
              av dette, ber vi om at du informerer oss om alle relevante
              endringer i opplysningene. Du kan sende dine endringer til
              fredrik@flexboks,com.
            </li>
            <br />
            <li>
              4.4. Dersom det kontraktsmessige forholdet mellom deg og/eller
              ditt selskap og Flexboks avsluttes, beholder vi opplysningene for
              følgende formål:
            </li>
            <br />
            <li>
              1) Etterfølgende markedsføring av våre tjenester og produkter i en
              periode på 5 år. Du kan allikevel til enhver tid reservere deg mot
              henvendelser fra oss, samt be oss om å slette opplysningene.
            </li>
            <br />
            <li>
              2) Oppbevaring av dokumentasjon i henhold til gjeldende
              myndighetskrav. I slike tilfeller vil vi beholde opplysningene så
              lenge det kreves i henhold til de angjeldende myndighetskravene.
            </li>
            <br />
            <li>
              3) I tillegg lagrer vi opplysningene i anonymisert form til bruk
              for statistikk knyttet til våre tjenester og produkter.
            </li>
            <br />
            <li>
              4.5. Vi kryptere ikke opplysningene vi lagrer, men vi tar godt
              vare på dine opplysninger gjennom tilgangskontroll og
              hensiktsmessige tekniske og organisatoriske tiltak som
              kontrolleres og oppdateres kontinuerlig.
            </li>
            <br />
            <li>
              4.6. Vi sørger også for at opplysningene din er lagret på en måte
              slik at bare relevante ansatte med arbeidsmessige behov har
              tilgang til de opplysninger våre system har om deg og/eller din
              bedrift. Se også under punkt 5 nedenfor vedrørende
              samarbeidspartnere.
            </li>
            <br />
            <li>
              4.7. Vi vil ta alle rimelige og nødvendige skritt for å sikre at
              opplysningene behandles på en sikker måte og i samsvar med
              personvernerklæringen. Dessverre er overføring av opplysningene
              via internett ikke helt sikker. Selv om vi gjør vårt beste for å
              beskytte disse kan vi ikke garantere sikkerheten til opplysninger
              du sender inn på nettet før de er mottatt av oss. Vi overholder
              alle relevante sikkerhetsprosesser og sikkerhetskrav for å
              forhindre uautorisert tilgang, men du sender dine opplysninger til
              oss på egen risiko.
            </li>
            <br />
            <h5>5. Samarbeidspartnere</h5>
            <br />
            <br />
            <li>
              5.1. Flexboks bruker ulike samarbeidspartnere som tilbyr Flexboks
              -tjenester. I denne forbindelse fungerer våre partnere som
              databehandler, dvs. at de behandler opplysninger på våre vegne og
              i samsvar med skriftlige instruksjoner om hvor og hvordan de skal
              behandle opplysningene.
            </li>
            <br />
            <li>
              5.2. Vi bruker for eksempel IT-leverandører i forbindelse med
              generell drift og vedlikehold av vårt nettsted, hosting av våre
              IT-løsninger og kundedatabaser, samt kundesupport. I denne
              forbindelse kan opplysningene dine overføres til land utenfor EU /
              EØS (et "tredjeland"). Overføringer av dine opplysninger til et
              tredjeland kan bare gjøres dersom EU-kommisjonen har fastslått at
              tredjelandet har et tilstrekkelig beskyttelsesnivå under de
              nåværende EU-personopplysninger. Opplysninger kan også behandles
              av en ansatt utenfor Norge/Danmark som jobber for oss eller en av
              våre dataleverandører.
            </li>
            <br />
            <li>
              5.3. Våre databehandlere kan ikke benytte opplysningene til andre
              formål enn til levering av tjenester til Flexboks i henhold til
              våre skriftlige instruksjoner.
            </li>
            <br />
            <li>
              5.4. Der det er formålstjenlig vil vi gi fra oss de opplysningene
              vi måtte ha om deg til smittesporingsteamet i den hensikt å
              begrense spredningen av covid-19.
            </li>
            <br />
            <br />
            <h5>6. Dine rettigheter</h5>
            <br />
            <br />
            <li>
              6.1. Som registrert kunde/bruker har du i henhold til gjeldende
              lovgivning rett til å kreve innsikt i, og rette opp eller slette
              opplysninger om deg. Du har også rett til å begrense bruk av dine
              opplysninger samt motsette deg bruk av informasjonen.
            </li>
            <br />
            <li>
              6.2. Eventuelt kan du kontakte oss som angitt ovenfor på
              post@flexboks.com.
            </li>
            <br />
            <br />
            <h5>7. Klage til Datatilsynet</h5>
            <br />
            <br />
            7.1. Du har mulighet til å klage på bruken av dine opplysninger.
            Klagen skal sendes til Datatilsynet: www.datatilsynet.no.
            <br />
            <h5>8. Cookies</h5>
            <br />
            <br />
            <li>8.1. Hva er cookies:</li>
            <br />
            <li>
              8.1.1. Cookies er en enkeltekstfil som hjemmesidens server legger
              på din datamaskin, tablet eller smartphone.
            </li>
            <br />
            <li>8.2. Hvorfor bruker man cookies:</li>
            <br />
            <li>
              8.2.1. Brukes til å holde oversikt over hvor på nettstedet
              brukeren beveger seg.
            </li>
            <br />
            <li>
              8.2.2. Gir brukeren mulighet til å starte der hvor de slapp sist
              de besøkte nettstedet.
            </li>
            <br />
            <li>8.2.3. Husker dine login opplysninger.</li>
            <br />
            <li>8.2.4. Husker brukerens preferanser.</li>
            <br />
            <li>
              8.2.5. Markedsføring overfor brukere etter besøk på nettstedet.
            </li>
            <br />
            <li>8.2.6. Optimering av nettstedets brukervennlighet.</li>
            <br />
            <li>
              8.2.7. Cookies kan ikke se hvem du er, hva du heter, hvor du bor
              eller om datamaskinen brukes av en eller flere personer. Cookies
              kan heller ikke spre datavirus eller andre skadelige programmer.
            </li>
            <br />
            <li>8.3. Vår bruk av cookies:</li>
            <br />
            <li>
              8.3.1. Flexboks bruker cookies for å forbedre din opplevelse av
              våre nettsider, vurdere bruken av de enkelte elementer på
              nettstedet, og til å støtte markedsføringen av våre tjenester og
              produkter.
            </li>
            <br />
            <li>8.4. Hvor lenge oppbevares cookies:</li>
            <br />
            <li>
              8.4.1. Cookies sletter seg selv etter ett vist antall måneder (kan
              variere), men de fornyes etter hvert besøk på våre nettsider.
            </li>
            <br />
            <li>8.5. Slik sletter eller blokkerer du cookies:</li>
            <br />
            <li>8.5.1. http://minecookies.org/cookiehandtering</li>
            <br />
            <li>
              8.5.2. Dersom du sletter eller blokkerer cookies skal du være
              oppmerksom på at det kan påvirke din bruk på vår nettside og dens
              funksjonalitet.
            </li>
            <br />
            <li>8.6. Har du spørsmål:</li>
            <br />
            <li>
              8.6.1. Har du spørsmål om cookies og behandling av
              personopplysninger, er du velkommen til å kontakte oss på
              post@flexboks.com.
            </li>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
