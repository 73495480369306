import React from 'react';

// reactstrap components
import { Button, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import bg from 'assets/img/Splash_2_std.jpg';
// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          'translate3d(0,' + windowScrollTop + 'px,0)';
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url(${bg})`,
          }}
          ref={pageHeader}
        ></div>
        <div className="FrontPageLogo"></div>
        <div className="content-center">
          <Container>
            <h1 className="title" style={{ overflowWrap: 'break-word' }}>
              Enkel besøksregistrering for din bedrift !
            </h1>
            <h3 className="title">Registrer deg nå.</h3>
            <div className="text-center">
              <Button outline color="primary">
                <Link to="/registrer">Registrer</Link>
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
