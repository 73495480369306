/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';

// core components

function FooterBlackSocial() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <div className="content">
            <Row>
              <Col md="4">
                <h4>Om oss</h4>
                <p className="footerText">
                  Smittesporing.app er utviklet av Flexboks AS.
                  <br />
                  Flexboks AS er et programmerings- og utviklingsselskap
                  lokalisert i Mosjøen.
                </p>
                <br /> <br />
                <p className="footerText">
                  Flexboks AS <br />
                  Orgnr 925 595 128
                </p>
                <img />
              </Col>

              <Col md="4">
                <h4>Kontakt oss</h4>
                <p className="footerText">
                  For spørsmål om Smittesporing.app eller vår virksomhet, send
                  oss gjerne en mail:{' '}
                  <a href="mailto:post@flexboks.com" target="_blank">
                    post@flexboks.com
                  </a>
                </p>
              </Col>
              <Col md="4">
                <h4>Personvern</h4>
                <p className="footerText">
                  Vi behandler personopplysninger i tråd med den til enhver tid
                  gjeldende personopplysningslovgivningen i Norge og EU.
                  <br />
                  Personopplysningene som samles inn slettes automatisk etter 10
                  dager.
                </p>
              </Col>
              <Col
                md={{ size: 6, offset: 3 }}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <a href="https://flexboks.com">
                  <img
                    alt="Flexboks logo"
                    src="https://flexboks.com/flexboks_hvit.png"
                  />
                </a>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Utviklet av{' '}
            <a href="https://flexboks.com" target="_blank">
              Flexboks AS
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlackSocial;
