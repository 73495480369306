import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from '@material-ui/core';

import Login from 'assets/img/login.png';

import FooterBlackSocial from 'components/Footers/FooterBlackSocial.js';
import bg from 'assets/img/Splash_2_std.jpg';
import nowLogo from 'assets/img/logo.png';
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar';
import { Link, useHistory } from 'react-router-dom';

import { fireAuth } from 'config/fire';

import Loader from 'react-loader-spinner';

export const LoginPage = () => {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [form, setform] = React.useState('login');
  const [loading, setloading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetMail, setresetMail] = React.useState('');
  const [dialogBox, setdialogBox] = React.useState({
    status: false,
    message: '',
  });

  React.useEffect(() => {
    document.body.classList.add('login-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  const history = useHistory();

  const signIn = () => {
    setloading(true);
    fireAuth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        if (auth) {
          setloading(false);
          history.push('/minside');
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          setdialogBox({ status: true, message: 'Feil passord' });
        } else {
          setdialogBox({ status: true, message: errorMessage });
        }
        console.log(error);
        setloading(false);
      });
  };

  const ResetPass = () => {
    setloading(true);
    fireAuth
      .sendPasswordResetEmail(resetMail)
      .then(function () {
        setdialogBox({
          status: true,
          message: 'Email resett OK, sjekk din mail',
        });
        history.push('/login');
        setloading(false);
      })
      .catch(function (error) {
        if (error.code == 'auth/user-not-found') {
          setdialogBox({
            status: true,
            message: 'Det er ingen brukere registrert med denne Email adressen',
          });
        } else {
          setdialogBox({
            status: true,
            message: error.message,
          });
        }
        setloading(false);
      });
  };

  const DialogBox = () => {
    return (
      <Dialog
        open={dialogBox.status}
        onClose={dialogBox.status}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { minWidth: '30vw' },
        }}
      >
        <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
          <span style={{ color: 'black' }}>{'Login'}</span>
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <img
            style={{
              height: '15vh',
              marginBottom: '10px',
            }}
            src={Login}
          />

          <DialogContentText id="alert-dialog-description">
            {dialogBox.message}
          </DialogContentText>

          <Box m="auto" style={{ marginTop: 30, marginBottom: 30 }}>
            <Button
              style={{
                width: '80%',
                height: '10%',
                marginTop: 20,
                backgroundColor: 'rgba(0,153,0,1)',
              }}
              variant="contained"
              onClick={() => handleClose()}
            >
              Okay !
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };
  const handleClose = () => {
    setdialogBox(false);
    setform('login');
  };

  const LoadingSpinner = () => {
    return (
      <div className="overlay">
        <Loader
          className="loader"
          type="ThreeDots"
          color="rgba(0,0,240,.6)"
          height="100"
          width="100"
        />
      </div>
    );
  };
  return (
    <>
      <FixedTransparentNavbar />
      <DialogBox />
      <div className="page-header header-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        ></div>
        <div className="content" style={{ height: '70vh' }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                {form == 'login' ? (
                  <Card className="card-login card-plain">
                    <Form action="" className="form" method="">
                      <CardHeader className="text-center">
                        <div className="loginLogo">
                          <img alt="Logo Login" src={nowLogo}></img>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={
                            'no-border input-lg' +
                            (firstFocus ? ' input-group-focus' : '')
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Epost adresse..."
                            type="text"
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          ></Input>
                        </InputGroup>
                        <InputGroup
                          className={
                            'no-border input-lg' +
                            (lastFocus ? ' input-group-focus' : '')
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons text_caps-small"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Passord..."
                            type="password"
                            onFocus={() => setLastFocus(true)}
                            onBlur={() => setLastFocus(false)}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                          ></Input>
                        </InputGroup>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          style={{ width: '100%' }}
                          variant="contained"
                          block
                          className="btn-round"
                          color="info"
                          onClick={() => signIn()}
                          size="lg"
                        >
                          Log inn
                        </Button>
                      </CardFooter>
                      <div className="pull-left">
                        <h6>
                          <Button className="link footer-link">
                            <Link style={{ color: '#fff' }} to="/registrer">
                              Registrer bruker
                            </Link>
                          </Button>
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          <Button
                            className="link footer-link"
                            onClick={(e) => setform('reset')}
                          >
                            Resett passord
                          </Button>
                        </h6>
                      </div>
                    </Form>
                  </Card>
                ) : (
                  <Card className="card-login card-plain">
                    <Form action="" className="form" method="">
                      <CardHeader className="text-center">
                        <div className="loginLogo">
                          <img alt="Logo Login" src={nowLogo}></img>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={
                            'no-border input-lg' +
                            (firstFocus ? ' input-group-focus' : '')
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Epost adresse..."
                            type="text"
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                            onChange={(e) => setresetMail(e.target.value)}
                            value={resetMail}
                          ></Input>
                        </InputGroup>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          style={{ width: '100%' }}
                          variant="contained"
                          block
                          className="btn-round"
                          color="info"
                          onClick={() => ResetPass()}
                          size="lg"
                        >
                          Resett passord
                        </Button>
                      </CardFooter>
                    </Form>
                  </Card>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {loading ? <LoadingSpinner /> : null}
      </div>
      <FooterBlackSocial />
    </>
  );
};
