import React from 'react';

export default React.createContext({
  user: null,
  subscriptions: null,
  stats: null,
  getSubs: () => {},
  setUserAuth: () => {},
  updateUserSubs: () => {},
  changeLayout: () => {},
  getStats: () => {},
  uploadFile: () => {},
  addUserSubs: () => {},
  uploadLogo: () => {},
  RefreshSubs: () => {},
  DeleteSubs: () => {},
  refreshList: () => {},
});
