/*eslint-disable*/
import React from 'react';
import axios from 'axios';

import { Button } from '@material-ui/core';
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import RetningslinjeHeader from 'components/Headers/RetningslinjeHeader.js';
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar';
import FooterBlackSocial from 'components/Footers/FooterBlackSocial';

import { useHistory } from 'react-router-dom';
import { RetningsLinjerBrukere } from './RetningslinjerBrukere';
import { RetningsLinjerKunder } from './RetningslinjerKunder';
import { OfteStilte } from './OfteStilte';
import { Databehandler } from './Databehandler';

export const Retningslinjer = (props) => {
  // HIDE NAV
  React.useEffect(() => {
    document.body.classList.add('retningslinjer-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('retningslinjer-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  const [render, setrender] = React.useState('');
  // ROUTE
  let history = useHistory();

  const ScrollToLocation = () => {
    var elmnt = document.getElementById('ScrollTo');
    elmnt.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const RenderRetningslinjer = () => {
    switch (render) {
      case 'Brukere':
        return <RetningsLinjerBrukere />;
      case 'Kunder':
        return <RetningsLinjerKunder />;
      case 'oftestilte':
        return <OfteStilte />;
      case 'Databehandler':
        return <Databehandler />;
      default:
        return (
          <Col className="mr-auto ml-auto" md="10">
            <h3 className="title">
              Trykk på en Rettningslinje for å lese mer!
            </h3>
          </Col>
        );
    }
  };

  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper">
        <RetningslinjeHeader />
        <div className="section">
          <div className="about-description text-center">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="10">
                  <h2 className="title">
                    Vi sikrer deg, og vi sikrer dine data!
                  </h2>
                </Col>
                <Col
                  className="mr-auto ml-auto"
                  md="10"
                  style={{ marginBottom: 50 }}
                >
                  <div className="retningslinjer">
                    <h4 style={{ fontWeight: 'bold' }}>Flexboks AS</h4>
                    Flexboks AS som har utviklet Smittesporing.app, har utviklet
                    denne i samråd med datatilsynet og den enhver tid gjeldende
                    personopplysningslovgivningen.
                    <br />
                    <a href="https://www.datatilsynet.no/rettigheter-og-plikter/virksomhetenes-plikter/">
                      Virksomhetens plikter
                    </a>
                    <br />
                    Våre retningslinjer er utarbeidet for å iverata alle våre
                    kunder av produktet, brukere av produktet, og oss som
                    bedrift på en sikker og trygg måte.
                    <br />
                    Vi vil altid tilstrebe å etterleve alle lover og regler iht
                    personopplysningslovgivningen.
                    <br />
                    <br />
                    {/* <h4 style={{ fontWeight: 'bold' }}>Databehandleravtale</h4>
                    Smittesporing.app innhenter ikke data på vegne av noen
                    andre, vi innhenter data på vegne av Smittesporing.app.
                    <br />
                    Dette betyr at Smittesporing.app vil være
                    behandlingsansvarlig for alle data som er innhentet. <br />
                    Smittesporing.app har databehandleravtale mot våre
                    underleverandører som står for hosting og server tjenester. */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 4, offset: 2 }}>
                  <div className="info info-hover">
                    <Button
                      onClick={() => {
                        ScrollToLocation();
                        setrender('Kunder');
                      }}
                    >
                      <div className="icon icon-success icon-circle">
                        <i className="now-ui-icons business_bank"></i>
                      </div>
                    </Button>
                    <h4 className="info-title">Retningslinjer kunder</h4>
                    <p className="description">
                      Dette er for deg og din bedrift som har registrert
                      abonnement hos Smittesporing.app
                    </p>
                  </div>
                </Col>

                <Col md="4">
                  <div className="info info-hover">
                    <Button
                      onClick={() => {
                        ScrollToLocation();
                        setrender('Brukere');
                      }}
                    >
                      <div className="icon icon-info icon-circle">
                        <i className="now-ui-icons users_circle-08"></i>
                      </div>
                    </Button>
                    <h4 className="info-title">Retningslinjer brukere</h4>
                    <p className="description">
                      Dette er for deg som benytter Smittesporing.app til å
                      registrere ditt besøk
                    </p>
                  </div>
                </Col>
                <Col md={{ size: 4, offset: 2 }}>
                  <div className="info info-hover">
                    <Button
                      onClick={() => {
                        ScrollToLocation();
                        setrender('oftestilte');
                      }}
                    >
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons business_bulb-63"></i>
                      </div>
                    </Button>
                    <h4 className="info-title">Ofte stilte spørsmål</h4>
                    <p className="description">
                      Her er en samling med ofte stilte spørmsål. Om du ikke
                      finner svaret på det du leter etter her, vennligst ta
                      kontakt !
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-hover">
                    <Button
                      onClick={() => {
                        ScrollToLocation();
                        setrender('Databehandler');
                      }}
                    >
                      <div className="icon icon-primary icon-circle">
                        <i className="now-ui-icons files_single-copy-04"></i>
                      </div>
                    </Button>
                    <h4 className="info-title">Databehandleravtale</h4>
                    <p className="description">
                      Databehandleravtale ved registrering som kunde
                    </p>
                  </div>
                </Col>
              </Row>
              <div id="ScrollTo">
                <RenderRetningslinjer />
              </div>
            </Container>
          </div>
        </div>
        <FooterBlackSocial />
      </div>
    </>
  );
};
