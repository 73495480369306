import React from 'react';

import * as d3 from 'd3';
import { scaleLinear, scaleBand } from 'd3-scale';

// reactstrap components
import { Row, Col } from 'reactstrap';
import AuthContext from 'Context/AuthContext';
// core components

export const StatsGraph = (props) => {
  const [stats, setStats] = React.useState([]);
  const containerRef = React.useRef(null);
  const context = React.useContext(AuthContext);

  var width,
    height = 0;

  var margin = {
    top: 30,
    right: 0,
    bottom: 30,
    left: 40,
  };

  React.useEffect(() => {
    if (props.width.current) {
      width =
        props.width.current.offsetWidth - props.width.current.offsetWidth * 0.1;
      height = width / (16 / 9);
    }
  }, []);

  React.useEffect(async () => {
    var data = props.data;

    const xSelector = (d) => d.date;
    const ySelector = (d) => d.value;

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(
          data.map((d) => {
            return d.value;
          })
        ) * 1.2,
      ])
      .range([0, height]);

    const yScaleAxis = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(
          data.map((d) => {
            return d.value;
          })
        ) * 1.2,
      ])
      .range([height, 0]);

    const xScale = d3
      .scaleBand()
      .range([margin.left, width])
      .domain(data.map((d) => xSelector(d)))
      .padding(0.3);

    var svg = d3
      .select(containerRef.current)
      .append('svg')
      .attr('viewBox', [0, 0, width + 30, height + 30])
      .style('border', '1px solid black');

    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(xScale));

    svg
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScaleAxis));

    var selection = svg.selectAll('rect').data(data);

    selection
      .enter()
      .append('rect')
      .attr('width', xScale.bandwidth())
      .attr('height', (d) => yScale(d.value))
      .attr('x', (d) => xScale(d.date))
      .attr('y', (d) => height - yScale(d.value))
      .attr('fill', 'orange');
  });

  return <div ref={containerRef} style={{ textAlign: 'center' }}></div>;
};
