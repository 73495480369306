import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

// core components
import LandingPageHeader from 'components/Headers/LandingPageHeader.js';
import FooterDefault from 'components/Footers/FooterDefault.js';
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar';

import salgsplakat1 from 'assets/img/Salgsplakat_1.png';
import salgsplakat2 from 'assets/img/Salgsplakat_2.png';
import salgsplakat3 from 'assets/img/Salgsplakat_3.png';
import Banner from 'assets/img/FB_Banner_flexboks.jpg';

import bg from 'assets/img/FB_Banner_flexboks.jpg';
import FooterBlackSocial from 'components/Footers/FooterBlackSocial';

// CONTEXT
import AuthContext from 'Context/AuthContext';

export const MainView = (props) => {
  const [pills, setPills] = React.useState('1');
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  return (
    <>
      <FixedTransparentNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <div className="about-description text-center">
            <div className="features-3">
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="12">
                    <img src={Banner} style={{ borderRadius: 20 }} />
                  </Col>
                  <Col
                    className="mr-auto ml-auto"
                    md="10"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <h2 className="title">Slik fungerer det</h2>
                    <p className="description">
                      Rask og enkel besøksregistrering for å sikre evt.
                      smittesporing ved behov.
                      <br />
                      Smittesporing.app er et system for besøksregistrering hvor
                      vi har fokusert på brukervennlighet og sikkerhet.
                      <br />
                      Det tar kun 2 minutter å sette opp systemet hos din
                      bedrift, og for brukerene tar det kun 5 sekunder å
                      registrere sitt besøk.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <img src={salgsplakat1} />

                      <h4 className="info-title">Registrer din bedrift</h4>
                      <p className="description">
                        Registreringen tar kun 2 minutter, dette gjør du her{' '}
                        <a href="https://smittesporing.app/registrer">
                          Registrering
                        </a>
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <img src={salgsplakat2} />

                      <h4 className="info-title">
                        Skriv ut plakaten med QR-koden
                      </h4>
                      <p className="description">
                        Ved registrering får du tilsendt en plakat på mail med
                        din QR-kode.
                        <br />
                        Denne QR koden er unik og går til din bedrifts
                        besøksregistreringsside.
                        <br />
                        Heng opp plakaten godt synlig ved inngangen, og/eller
                        andre steder i ditt lokale.
                        <br />
                        Besøkende scanner koden og registrerer sitt besøk på få
                        sekunder.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <img src={salgsplakat3} />

                      <h4 className="info-title">Full kontroll</h4>
                      <p className="description">
                        Oversikt over dine besøkende blir lagret trygt og
                        sikkert i en database.
                        <br />
                        For at evt. smittesporing skal fungere effektivt er det
                        viktig at så mange som mulig bruker systemet.
                        <br /> Det er derfor viktig at du oppfordrer de
                        besøkende til å registrere besøket når de kommer.
                        <br /> Takk for at du bidrar til å begrense smitte!
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>

        <FooterBlackSocial />
      </div>
    </>
  );
};
