import React from 'react';
import axios from 'axios';
// reactstrap components
import {
  // Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Form,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Media,
  UncontrolledTooltip,
} from 'reactstrap';

import {
  Button,
  TextField,
  Grid,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Select,
  MenuItem,
  // FormGroup,
  FormControlLabel,
  Icon,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import { useLocation } from 'react-router-dom';

// core components
import ConfirmationHeader from 'components/Headers/ConfirmationHeader';
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar.js';
import FooterBlackSocial from 'components/Footers/FooterBlackSocial';

export const ConfirmationScreen = (props) => {
  const location = useLocation();

  const url = `https://smittesporing.app/c/${location.state.id}`;

  React.useEffect(() => {
    document.body.classList.add('blog-post');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('blog-post');
      document.body.classList.remove('sidebar-collapse');
    };
  });
  return (
    <>
      <>
        <FixedTransparentNavbar />
        <FixedTransparentNavbar />
        <div className="wrapper">
          <div className="ConfirmationBox">
            <Card>
              <CardBody>
                <CardTitle
                  className="text-center PrimaryTextColor"
                  tag="h4"
                  style={{ marginTop: 50 }}
                >
                  Velkommen som kunde
                </CardTitle>
                <h5>Du er nå registrert som bruker hos Smittesporing.app</h5>
                <p>Din unike Qr kode vil bli sendt per mail.</p>
                <h4>
                  din unike url er <br />
                  <a style={{ color: 'blue' }} href={url} target="_blank">
                    {url}
                  </a>
                </h4>
              </CardBody>
            </Card>
          </div>
          <ConfirmationHeader />
          <FooterBlackSocial />
        </div>
      </>
    </>
  );
};
